:root {
  --container-sm: 480px;
  --container-md: 768px;
  --container-lg: 1024px;

  --container-max-width-sm: 480px;
  --container-max-width-md: 768px;
  --container-max-width-lg: 1024px;

  --size-8: 8px;
  --size-16: 16px;
  --size-24: 24px;
  --size-48: 48px;
  --size-96: 96px;
}
