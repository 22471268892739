.component {
  padding-top: var(--size-24);
  padding-bottom: var(--size-24);

  @media (--viewport-md) {
    padding-top: var(--size-48);
    padding-bottom: var(--size-48);
  }
}

.inner {
  text-align: center;

  & > *:not(:last-child) {
    margin-bottom: var(--size-24);
  }
}

.title {
  font-size: var(--font-size-60);
  font-size: var(--font-size-60-100);
}
