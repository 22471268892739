.page {
  display: flex;
  flex-direction: column;
  position: relative;

  & > .sectionLayout:not(:last-child) {
    margin-bottom: var(--size-24);

    @media (--viewport-md) {
      margin-bottom: var(--size-48);
    }
  }

  & > .soundButton {
    position: fixed;
    bottom: var(--size-16);
    left: var(--size-16);
    width: 48px;
    height: 48px;

    @media (--viewport-md) {
      bottom: var(--size-24);
      left: var(--size-24);
      width: 60px;
      height: 60px;
    }
  }
}

.soundButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 2px 2px 15px -5px rgba(0, 0, 0, 0.6);
  background-color: var(--color-gray-100);
  font-size: var(--font-size-18);
  font-size: var(--font-size-18-22);

  @media (--viewport-md) {
    font-size: var(--font-size-24);
    font-size: var(--font-size-24-32);
  }
}

.introduction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: var(--size-24);

  @media (--viewport-md) {
    padding-top: var(--size-48);
  }

  & > *:not(:last-child) {
    margin-bottom: var(--size-24);
  }

  & > .description {
    max-width: 850px;
  }
}

.introduction {
  text-align: center;
}

.title {
  font-size: var(--font-size-60);
  font-size: var(--font-size-60-100);
}

.toiletLabel {
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-52);
  text-align: center;
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-heading);
  line-height: 1.1;
  text-transform: uppercase;
}

.main {
  display: flex;
  justify-content: center;

  & > *:not(:last-child) {
    margin-right: var(--size-24);

    @media (--viewport-md) {
      margin-right: var(--size-48);
    }

    @media (--viewport-lg) {
      margin-right: var(--size-96);
    }
  }
}

.componentToilet {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & > .toiletIcon {
    width: 135px;
    margin-bottom: var(--size-24);

    @media (--viewport-md) {
      width: 250px;
    }

    @media (--viewport-lg) {
      width: 300px;
    }
  }
}

.toiletIcon {
  & > * {
    width: 100%;
  }
}
