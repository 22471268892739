.componentBase {
  display: flex;
  justify-content: center;

  & > .layout {
    width: 100%;
  }
}

.layout {
  padding-left: var(--size-16);
  padding-right: var(--size-16);

  @media (--viewport-md) {
    padding-left: var(--size-24);
    padding-right: var(--size-24);
  }
}

.componentSm {
  & > .layout {
    max-width: var(--container-max-width-sm);
  }
}

.componentMd {
  & > .layout {
    max-width: var(--container-max-width-md);
  }
}

.componentLg {
  & > .layout {
    max-width: var(--container-max-width-lg);
  }
}
