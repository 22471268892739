.component {
  padding-top: var(--size-24);
  padding-bottom: var(--size-24);

  @media (--viewport-md) {
    padding-top: var(--size-48);
    padding-bottom: var(--size-48);
  }
}

.inner {
  text-align: center;

  & > *:not(:last-child) {
    margin-bottom: var(--size-24);
  }
}

.title {
  font-size: var(--font-size-60);
  font-size: var(--font-size-60-100);
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  background-color: var(--color-gray-900);
  color: var(--color-gray-100);
  padding: var(--size-8) var(--size-16);
}
