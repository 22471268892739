.component {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  flex-shrink: 0;
}

.main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  & > * {
    height: 100%;
  }
}

.footer {
  flex-shrink: 0;
}

.globalError {
  position: fixed;
  z-index: 3;
  flex-shrink: 0;
  top: 0;
  left: 0;
  right: 0;
  color: red;
}
