.component {
  padding-top: var(--size-24);
  padding-bottom: var(--size-24);
  font-size: var(--font-size-14);
  font-size: var(--font-size-14-16);
}

.inner {
  text-align: center;
}

.seperator {
  margin-left: var(--size-8);
  margin-right: var(--size-8);
}

.link {
  color: var(--color-gray-900);
  
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
