:root {
  --font-family-base: 'Space Grotesk', sans-serif;
  --font-weight-base-400: 400;

  --font-family-heading: 'Barlow Condensed', sans-serif;
  --font-weight-heading: 800;

  --font-size-unit: 1px;
  --font-size-viewport-min: 480;
  --font-size-viewport-max: 1920;

  --font-size-growth: calc(1 / (var(--font-size-viewport-max) - var(--font-size-viewport-min)));
  --m: calc((var(--font-size-growth) * 100vw) - (var(--font-size-growth) * var(--font-size-viewport-min) * var(--font-size-unit)));

    /**
   * A font-size variable consisting of 2 numbers indicates a responsive font-size.
   * Make sure to include the fallback definition (consisting of only the first number),
   * above the responsive one:
   *
   * font-size: var(--font-size-15);
   * font-size: var(--font-size-15-18);
   *
   * A responsive font-size follows the format: clamp(${a}px, calc(${a}px + ${c} * var(--m)), ${b}px),
   * where c = b - a (unitless).
   */

  --font-size-14: 14px;
  --font-size-18: 18px;
  --font-size-24: 24px;
  --font-size-32: 32px;
  --font-size-60: 60px;

  --font-size-14-16: clamp(14px, calc(14px + 2 * var(--m)), 16px);
  --font-size-18-22: clamp(18px, calc(18px + 4 * var(--m)), 22px);
  --font-size-24-32: clamp(24px, calc(24px + 8 * var(--m)), 32px);
  --font-size-32-52: clamp(32px, calc(32px + 20 * var(--m)), 52px);
  --font-size-60-100: clamp(60px, calc(60px + 40 * var(--m)), 100px);
}
