.rotatingOuterPart,
.rotatingScrew {
  transition-duration: 0.3s;
  transition-property: transform;
  transform-origin: center;
}

.isInUse {
  transform: rotate(180deg);
}
